const getPlausibleDomain = () => {
  console.log('PlsDomain', process.env.EXPO_PUBLIC_PLAUSIBLE_DOMAIN)
  return process.env.EXPO_PUBLIC_PLAUSIBLE_DOMAIN ?? 'gems.xyz'
}

export const enablePlausible = () => {
  const script = document.createElement('script')
  script.setAttribute('defer', '')
  script.setAttribute('src', 'https://plausible.io/js/script.js')
  script.setAttribute('data-domain', getPlausibleDomain())
  document.head.appendChild(script)
}
