import React from 'react'
import {TextProps} from 'react-native'
import {PathProps, SvgProps} from 'react-native-svg'
import {Image} from 'expo-image'

type Props = {
  fill?: PathProps['fill']
  style?: TextProps['style']
} & Omit<SvgProps, 'style'>

export const Logo = React.forwardRef(function LogoImpl(props: Props) {
  const {...rest} = props
  // @ts-ignore it's fiiiiine
  const size = parseInt(rest.width || 32)

  return (
    <Image
      source={require('../../../assets/logo/gems192.png')}
      accessibilityLabel="Gems Navbar Logo"
      accessibilityHint=""
      accessibilityIgnoresInvertColors
      style={[{height: size, aspectRatio: 1, objectFit: 'contain'}]}
    />
  )
})
