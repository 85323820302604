export type TokenInfo = {
  address: string
  name: string
  logoURI: string
  freeze_authority: string
  minted_at: string
  symbol: string
  tags: string[]
}

// todo: move to separate hook for Jupiter API, check if not deprecated, use react-query
export const getJupiterConfig = () => {
  return {
    endpoint:
      'https://white-divine-pond.solana-mainnet.quiknode.pro/f26f11ca9357fd39ca4c79a9222be24ba3e2ab39',
  }
}

export const fetchTokenInfo = async (tokenAdress: string) => {
  let res = await fetch(`https://tokens.jup.ag/token/${tokenAdress}`, {
    method: 'GET',
  })
  let data = res.ok ? ((await res.json()) as TokenInfo) : null
  return data ? data : null
}
