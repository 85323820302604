import React, {useMemo} from 'react'

import {useModalControls} from '#/state/modals'
import {flatten, useTheme, web} from '#/alf'
import {InlineLinkProps} from '#/components/Link'
import {Text} from '#/components/Typography'
import {useInteractionState} from './hooks/useInteractionState'

export const getTokensFromTextSegment = (text: string) => {
  const words = text.split(/\s+/)
  return words.filter(word => word.length === 43 || word.length === 44)
}

export const isTokenInTextSegment = (text: string) => {
  return getTokensFromTextSegment(text).length > 0
}

export const isTokenInText = (text: string) => {
  return isTokenInTextSegment(text)
}

export const mapTextToTokenSegments = (text: string) => {
  const words = text.split(/\s+/)
  const segments: string[] = []
  let currentSegment = ''

  words.forEach(word => {
    if (word.length === 43 || word.length === 44) {
      if (currentSegment) {
        segments.push(currentSegment.trim())
        currentSegment = ''
      }
      segments.push(word)
    } else {
      currentSegment += ' ' + word
    }
  })
  if (currentSegment) {
    segments.push(currentSegment.trim())
  }
  return segments
}

const tokenLinkStyle = {
  color: '#e4fe20',
  fontWeight: 'bold',
}

type TokenLinkComboProps = Partial<InlineLinkProps> & {
  key?: string | number
  onLinkPress?: () => void
  style?: any
  textStyle?: any
}

export const TokenLinkCombo = ({
  children,
  key,
  onLinkPress,
  style,
  textStyle,
}: TokenLinkComboProps) => {
  const segments = useMemo(
    () => mapTextToTokenSegments(children as string),
    [children],
  )

  return segments.map((segment, index) => {
    if (isTokenInTextSegment(segment)) {
      return (
        <TokenLinkText
          key={`${key}-${index}`}
          onLinkPress={onLinkPress}
          label=""
          style={[style, tokenLinkStyle]}>
          {segment}{' '}
        </TokenLinkText>
      )
    }
    return (
      <Text key={`${key}-${index}`} emoji style={textStyle}>
        {segment}{' '}
      </Text>
    )
  })
}

type TokenLinkProps = Partial<InlineLinkProps> & {
  key?: string | number
  onLinkPress?: () => void
  style?: any
  textStyle?: any
}

export const TokenLinkText = ({children, style}: TokenLinkProps) => {
  const t = useTheme()
  const {
    state: hovered,
    onIn: onHoverIn,
    onOut: onHoverOut,
  } = useInteractionState()
  const {state: focused, onIn: onFocus, onOut: onBlur} = useInteractionState()
  const {
    state: pressed,
    onIn: onPressIn,
    onOut: onPressOut,
  } = useInteractionState()
  const flattenedStyle = flatten(style) || {}

  const {openModal} = useModalControls()

  const handlePress = async () => {
    if (Array.isArray(children) && typeof children[0] === 'string') {
      const tokenAddress = children[0] as string
      openModal({
        name: 'token-info',
        token: tokenAddress,
      })
    }
  }

  return (
    <Text
      selectable={true}
      accessibilityHint="Click to view token info"
      accessibilityLabel="Token Link"
      style={[
        {color: tokenLinkStyle.color},
        {fontWeight: tokenLinkStyle.fontWeight},
        (hovered || focused || pressed) && {
          ...web({outline: 0}),
          textDecorationLine: 'underline',
          textDecorationColor: flattenedStyle.color ?? t.palette.primary_500,
        },
        flattenedStyle,
      ]}
      onPress={handlePress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      {...web({
        dataSet: {
          // default to no underline, apply this ourselves
          noUnderline: '1',
        },
      })}>
      {children}
    </Text>
  )
}
