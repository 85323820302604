export const fetchDexScreenerTokenInfo = async (tokenAddress: string) => {
  const res = await fetch(
    `https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`,
  )
  const data = await res.json()
  console.log(data)
}

export const getLinkToDexScreener = (tokenAddress?: string) => {
  return `https://dexscreener.com/solana/${tokenAddress ?? ''}`
}
