import React from 'react'
import {Text} from 'react-native'
import {SvgProps} from 'react-native-svg'

import {atoms as a} from '#/alf'
import {useTheme} from '#/alf'

export function Logotype({...rest}: SvgProps) {
  const t = useTheme()

  return (
    <Text style={[a.text_sm, t.atoms.text_contrast_medium]} {...rest}>
      Gems
    </Text>
  )
}
