import React, {useEffect, useState} from 'react'
import {Image, StyleSheet, TouchableOpacity, View} from 'react-native'
import * as Clipboard from 'expo-clipboard'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useWallet} from '@solana/wallet-adapter-react'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {enforceLen} from '#/lib/strings/helpers.ts'
import {colors, s} from '#/lib/styles'
import {isWeb} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {ScrollView} from '#/view/com/modals/util'
import * as Toast from '#/view/com/util/Toast'
import {atoms as a} from '#/alf'
import {borderRadius} from '#/alf/tokens'
import {Link} from '#/components/Link'
import {Button} from '../util/forms/Button'
import {Text} from '../util/text/Text'
import {getLinkToDexScreener} from './jupiter/dexScreenerHelper'
import {type TokenInfo} from './jupiter/jupiterHelper'
import {fetchTokenInfo, getJupiterConfig} from './jupiter/jupiterHelper'

const jupiterConfig = getJupiterConfig() as any

export function Component({token}: {token: string}) {
  const pal = usePalette('default')
  const {closeModal} = useModalControls()
  const {isMobile} = useWebMediaQueries()
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | null>()
  const {_} = useLingui()
  const passthroughWalletContextState = useWallet()

  // To make sure passthrough wallet are synced
  useEffect(() => {
    // @ts-ignore
    if (!window.Jupiter.syncProps) return
    // @ts-ignore
    window.Jupiter.syncProps({passthroughWalletContextState})
  }, [passthroughWalletContextState, passthroughWalletContextState.connected])

  const fetchTokenInfoJup = async () => {
    const fetchedTokenInfo = await fetchTokenInfo(token as string)
    setTokenInfo(fetchedTokenInfo)
  }

  useEffect(() => {
    fetchTokenInfoJup()
    return () => {
      setTokenInfo(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const isAvailableToSwap = !!tokenInfo?.address && !!tokenInfo?.name

  const handleSwapClick = () => {
    // Implement translation logic
    // @ts-ignore
    window.Jupiter.init({
      ...jupiterConfig,
      formProps: {
        ...jupiterConfig.formProps,
        initialOutputMint: token,
      },
    })
    closeModal()
  }

  const onCopyAddress = () => {
    if (!tokenInfo?.address) return
    Clipboard.setStringAsync(tokenInfo.address)
    Toast.show('Token address copied to clipboard', 'clipboard-check')
  }

  return (
    <View testID="selfLabelModal" style={[pal.view, styles.container]}>
      <View style={styles.titleSection}>
        <Text type="title-lg" style={[pal.text, styles.title]}>
          Swap Token
        </Text>
      </View>

      <ScrollView style={[styles.btnContainer, pal.borderDark]}>
        {isAvailableToSwap ? (
          <View
            style={[
              styles.section,
              pal.border,
              {borderBottomWidth: 1, paddingHorizontal: isMobile ? 20 : 0},
            ]}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: 10,
                paddingBottom: 8,
              }}>
              <Image
                accessibilityIgnoresInvertColors
                testID="userAvatarImage"
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: borderRadius.full,
                }}
                resizeMode="cover"
                source={{
                  uri: tokenInfo?.logoURI,
                }}
                blurRadius={0}
              />
              <View
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                  maxWidth: '100%',
                  flexGrow: 1,
                }}>
                <Text type="title" style={pal.text}>
                  {tokenInfo?.name}
                </Text>

                {tokenInfo?.address && (
                  <Text style={[pal.textLight]}>
                    {enforceLen(tokenInfo?.address, 20, true, 'middle')}
                  </Text>
                )}
              </View>
              <TouchableOpacity
                testID="copyBtn"
                style={[
                  styles.copyBtn,
                  pal.view,
                  {paddingVertical: 2, paddingHorizontal: isMobile ? 2 : 18},
                ]}
                onPress={onCopyAddress}
                accessibilityRole="button"
                accessibilityLabel={'Copy to Clipboard'}
                accessibilityHint={'Copy to Clipboard'}>
                <View style={[styles.iconContainer, pal.btn, {marginRight: 0}]}>
                  <FontAwesomeIcon
                    icon="clone"
                    style={pal.text as FontAwesomeIconStyle}
                  />
                </View>
              </TouchableOpacity>
            </View>

            <View
              style={{
                paddingVertical: 10,
                display: 'flex',
                gap: 10,
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'stretch',
                justifyContent: 'space-evenly',
              }}>
              <Link
                style={[
                  a.rounded_sm,
                  a.overflow_hidden,
                  {
                    minWidth: 200,
                    borderWidth: 1,
                    paddingVertical: 10,
                    paddingHorizontal: 14,
                    borderColor: colors.blue3,
                  },
                ]}
                to={getLinkToDexScreener(token)}
                label={'Dex Screener'}>
                <Text style={[pal.link, styles.smBtnText]}>Dex Screener</Text>
              </Link>
              <Button
                style={[
                  a.rounded_sm,
                  a.overflow_hidden,
                  {
                    minWidth: 200,
                    paddingVertical: 11,
                    paddingHorizontal: 14,
                  },
                ]}
                type={'primary'}
                onPress={handleSwapClick}>
                <Text
                  style={[
                    styles.smBtnText,
                    {
                      color: colors.white,
                    },
                  ]}>
                  Swap
                </Text>
              </Button>
            </View>

            <View
              id="integrated-terminal"
              style={[{width: '100%', height: 0, margin: 0}]}
            />
          </View>
        ) : (
          <View
            style={[
              styles.section,
              pal.border,
              {borderBottomWidth: 1, paddingHorizontal: isMobile ? 20 : 0},
            ]}>
            <Text type="xl-bold" style={[pal.textLight]}>
              Token not found
            </Text>
          </View>
        )}
      </ScrollView>

      <View style={[styles.btnContainer, pal.borderDark]}>
        <TouchableOpacity
          testID="confirmBtn"
          onPress={closeModal}
          style={styles.btn}
          accessibilityRole="button"
          accessibilityLabel={_(msg`Confirm`)}
          accessibilityHint="">
          <Text style={[s.white, s.bold, s.f18]}>Close</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: isWeb ? 0 : 40,
  },
  titleSection: {
    paddingTop: isWeb ? 0 : 4,
    paddingBottom: isWeb ? 14 : 10,
  },
  title: {
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: 5,
  },
  description: {
    textAlign: 'center',
    paddingHorizontal: 32,
  },
  section: {
    borderTopWidth: 1,
    paddingVertical: 20,
  },
  copyBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 18,
    marginBottom: 1,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 30,
    marginRight: 12,
  },
  smBtnText: {
    fontWeight: '600',
    fontSize: 14,
    width: '100%',
    textAlign: 'center',
  },
  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 32,
    padding: 14,
    backgroundColor: colors.blue3,
  },
  btnContainer: {
    paddingTop: 20,
    paddingHorizontal: 20,
  },
})
